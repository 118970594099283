import Barba from 'barba.js'
import Scrollbar from 'smooth-scrollbar'

let _this = null

const transition = Barba.BaseTransition.extend({
  start: function () {
    _this = this

    Promise
      .all([_this.newContainerLoading, new Promise(_this.layersOut)])
      .then(_this.layersIn)
  },

  layersOut: (resolve, reject) => {
    const transitionElement = document.querySelector('.transition--layers')
    const transitionElementChild = document.querySelector('.transition--layers .transition__layer')
    const transitionListener = (e) => {
      transitionElementChild.removeEventListener(window.transitionEnd, transitionListener)
      resolve()
    }

    // Content Hide
    transitionElementChild.addEventListener(window.transitionEnd, transitionListener)
    transitionElement.classList.add('transition--active', 'transition--hide-content')
  },

  layersIn: () => {
    const transitionElement = document.querySelector('.transition--layers')
    const transitionElementChild = document.querySelector('.transition--layers .transition__layer')
    const transitionListener = (e) => {
      transitionElementChild.removeEventListener(window.transitionEnd, transitionListener)
      transitionElement.classList.remove('transition--active')
      transitionElement.classList.remove('transition--hide-content')
      transitionElement.classList.remove('transition--reveal-content')
      _this.done()
    }

    // Scroll to top
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
    Scrollbar.getAll().forEach(sb => sb.scrollTo(0, 0))

    // Swap container visibility
    const bodyClasses = _this.newContainer.querySelector('[data-body-class]')

    if (bodyClasses) {
      document.body.setAttribute('class', bodyClasses.dataset.bodyClass)
    } else {
      document.body.setAttribute('class', document.body.dataset.class)
    }

    _this.oldContainer.style.display = 'none'
    _this.newContainer.style.visibility = 'visible'

    // Content fade in
    transitionElementChild.addEventListener(window.transitionEnd, transitionListener)
    transitionElement.classList.add('transition--reveal-content')
  }
})

export const LayersTransition = () => transition
