import Barba from 'barba.js'
import { LayersTransition } from 'components/ux/transitions/page/layers'

const Kernel = window.Kernel

export class PageTransition {
  constructor () {
    Barba.Pjax.Dom.wrapperId = 'main'
    Barba.Pjax.Dom.containerClass = 'page'
    Barba.Pjax.cacheEnabled = !Kernel.debug
    Barba.Pjax.ignoreClassLink = 'no-transition'
    Barba.Prefetch.ignoreClassLink = 'a:not([data-prefetch])'

    // @AlexandraKlein
    Barba.Pjax.originalPreventCheck = Barba.Pjax.preventCheck
    Barba.Pjax.preventCheck = function (evt, element) {
      if (element && element.getAttribute('href') && element.getAttribute('href').indexOf('#') >= 0 &&
          element.pathname !== window.location.pathname) {
        return true
      } else {
        return Barba.Pjax.originalPreventCheck(evt, element)
      }
    }

    Barba.Pjax.getTransition = LayersTransition
  }

  run () {
    Barba.Pjax.start()

    this.subscribe()
  }

  subscribe () {
    Barba.Dispatcher.on('initStateChange', () => {
      Kernel.emitEvent('pagetransition.start')
    })

    Barba.Dispatcher.on('newPageReady', () => {
      Kernel.emitEvent('pagetransition.ready')
    })

    Barba.Dispatcher.on('transitionCompleted', () => {
      Kernel.emitEvent('pagetransition.end')

      if (typeof window.ga !== 'undefined' && typeof window.location.pathname !== 'undefined') {
        window.ga('send', 'pageview', window.location.pathname)
      }
    })
  }
}
