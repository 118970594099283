import Flickity from 'flickity-fade'

export default class Slider {
  constructor (slider, config) {
    this.elements = {
      _self: slider
    }

    this.config = config
    this._self = null
    this.init()
  }

  init () {
    this._self = new Flickity(this.elements._self, this.config)
  }

  recreate () {
    this._self.destroy()
    this._self = new Flickity(this.elements._self, this.config)
  }

  instance () {
    return this._self
  }

  destroy () {
    setTimeout(() => this._self.destroy(), 400)
  }
}
