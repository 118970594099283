const Kernel = window.Kernel

export class CustomCursor {
  constructor () {
    this.selectors = {
      _self: '.no-touchevents .cursor .cursor-follow',
      hover: '.no-touchevents a',
      disable: '.no-touchevents iframe, .no-touchevents [data-cursor-disable]',
      follow: '.no-touchevents [data-cursor-follow]'
    }

    this.classes = {
      active: 'cursor--active',
      hover: 'cursor--hover'
    }

    this.state = {
      active: false,
      hover: false,
      mouseX: 0,
      mouseY: 0
    }

    this.follow = {
      normal: {
        height: 20,
        width: 20
      },

      hover: {
        height: 11,
        width: 11
      }
    }

    this.elements = {}

    this.subscribed = false
  }

  run () {
    this.elements._self = document.querySelector(this.selectors._self)

    this.subscribe()

    document.querySelectorAll(this.selectors.hover).forEach(element => {
      element.addEventListener('mouseenter', e => this.hoverEnter())
      element.addEventListener('mouseleave', e => this.hoverLeave())
    })

    document.querySelectorAll(this.selectors.disable).forEach(element => {
      element.addEventListener('mouseenter', e => this.mouseLeave())
      element.addEventListener('mouseleave', e => this.mouseEnter())
    })

    document.querySelectorAll(this.selectors.follow).forEach(element => {
      element.addEventListener('mousemove', e => this.mouseMoveFollow(e, element.parentNode.querySelector(element.dataset.cursorFollow)), Kernel.passiveEvents ? { passive: true } : false)
    })

  }

  subscribe () {
    if (this.elements._self && !this.subscribed) {
      document.documentElement.addEventListener('mouseenter', e => this.mouseEnter(), Kernel.passiveEvents ? { passive: true } : false)
      document.documentElement.addEventListener('mouseleave', e => this.mouseLeave(), Kernel.passiveEvents ? { passive: true } : false)
      document.documentElement.addEventListener('mousemove', e => this.mouseMove(e), Kernel.passiveEvents ? { passive: true } : false)

      this.showFollow()
      this.update()
    }
  }

  mouseEnter () {
    this.showFollow()
  }

  mouseLeave () {
    this.hideFollow()
  }

  mouseMove (e) {
    if (this.state.active) {
      this.state.mouseX = e.clientX - (this.state.hover ? this.follow.hover.width : this.follow.normal.width)
      this.state.mouseY = e.clientY - (this.state.hover ? this.follow.hover.height : this.follow.normal.height)
    }
  }

  mouseMoveFollow (e, element) {
    if (element) {
      const box = element.parentNode.getBoundingClientRect()

      element.style.setProperty('transform', `translate3d(${e.clientX - box.left}px, ${e.clientY - box.top}px, 0)`)
    }
  }

  hoverEnter () {
    this.state.hover = true
    this.elements._self.classList.add(this.classes.hover)
  }

  hoverLeave () {
    this.state.hover = false
    this.elements._self.classList.remove(this.classes.hover)
  }

  showFollow () {
    this.elements._self.classList.add(this.classes.active)
    this.state.active = true
  }

  hideFollow () {
    this.elements._self.classList.remove(this.classes.active)
    this.state.active = false
  }

  update () {
    if (this.state.active) {
      this.elements._self.style.setProperty('transform', `translate3d(${this.state.mouseX}px, ${this.state.mouseY}px, 0)`)
    }

    requestAnimationFrame(() => this.update())
  }
}
