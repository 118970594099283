import Scrollbar from 'smooth-scrollbar'
import { FilterEventPlugin } from 'components/ux/scroll/plugins/filter-event'

const Kernel = window.Kernel

export class SmoothScroll {
  constructor () {
    this.selectors = {
      self: '[data-scrollbar]',
      fixed: '[data-scrollbar-fixed]',
      track: '.scrollbar-track-y'
    }

    this.config = {
      damping: 0.1,
      thumbMinSize: 20,
      renderByPixel: true,
      alwaysShowTracks: true,
      continuousScrolling: true,
      wheelEventTarget: null
    }

    this.instance = null

    Scrollbar.use(FilterEventPlugin)
  }

  run () {
    if (Kernel.isMobile !== true && document.documentElement.classList.contains('touchevents') !== true) {
      this.elements = {
        self: document.querySelector(this.selectors.self),
        fixed: document.querySelectorAll(this.selectors.fixed),
      }

      if (this.elements.self) {
        this.instance = Scrollbar.init(this.elements.self, this.config)

        this.elements.track = document.querySelector(this.selectors.track)

        if (this.elements.self.getAttribute('data-scroller') === 'false') {
          this.elements.self.setAttribute('data-scroller', true)
        }

        if (this.elements.fixed.length > 0) {
          this.instance.addListener(status => {
            this.elements.fixed.forEach(element => {
              element.style.transform = `translate3d(${status.offset.x}px, ${status.offset.y}px, 0)`
            })
          })
        }

        this.instance.addListener(status => {
          this.elements.track.style.setProperty('--s-height', `${this.instance.track.yAxis.thumb.offset + 32}px`)
        });

        this.subscriptions()
      }
    }
  }

  subscriptions () {
    Kernel.subscribeTo('component.menu.open', () => this.pause())
    Kernel.subscribeTo('object.modal.show', () => this.pause())
    Kernel.subscribeTo('component.menu.close', () => this.resume())
    Kernel.subscribeTo('object.modal.hide', () => this.resume())
    Kernel.subscribeTo('pagetransition.ready', () => {
      this.resume()
      this.updateFixedElements()
    })
  }

  updateFixedElements () {
    this.elements.fixed = document.querySelectorAll(this.selectors.fixed)
  }

  pause () {
    if (this.instance) {
      this.instance.updatePluginOptions('filterEvent', {
        blacklist: [/wheel/, /touch/, /key/]
      })
    }
  }

  resume () {
    if (this.instance) {
      this.instance.updatePluginOptions('filterEvent', {
        blacklist: []
      })
    }
  }
}
