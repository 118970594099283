const bodyScrollLock = require('body-scroll-lock')
const focusTrap = require('focus-trap')
const Kernel = window.Kernel

export class ModalObject {
  constructor (modal, toggle, classes, name = null) {
    this.elements = {
      _self: modal,
      toggle: toggle,
      content: document.querySelector('.content')
    }

    this.states = {
      closed: true
    }

    this.listeners = {
      keydown: e => this.keydown(e)
    }

    this.classes = classes || { content: { active: 'modal--active' } }
    this.name = name || 'Unknown'
    this.trap = focusTrap(this.elements._self, {
      onActivate: () => Kernel.emitEvent('object.modal.focustrap.activate', {name: this.name, target: this.elements._self}),
      onDeactivate: () => Kernel.emitEvent('object.modal.focustrap.deactivate', {name: this.name, target: this.elements._self})
    })

    Kernel.subscribeOnce('pagetransition.ready', () => this.destroy())

    const blockEvents = ['mousewheel', 'wheel']

    blockEvents.forEach(eventType => this.elements._self.addEventListener(eventType, e => e.stopPropagation()))
  }

  show () {
    if (this.elements.toggle && this.elements.toggle.length > 0) {
      this.elements.toggle.forEach(el => el.classList.add(this.classes.toggle.active))
      this.elements.toggle.forEach(el => el.setAttribute('aria-expanded', true))
    }

    this.elements._self.classList.add(this.classes.content.active)
    this.elements._self.setAttribute('aria-hidden', false)
    this.elements.content.setAttribute('aria-hidden', true)
    bodyScrollLock.disableBodyScroll(this.elements._self)
    this.trap.activate()
    this.states.closed = false

    this.elements._self.addEventListener('keydown', this.listeners.keydown)

    Kernel.emitEvent('object.modal.show', {name: this.name, target: this.elements._self})
  }

  hide () {
    if (this.elements.toggle) {
      this.elements.toggle.forEach(el => el.classList.remove(this.classes.toggle.active))
      this.elements.toggle.forEach(el => el.setAttribute('aria-expanded', false))
    }

    this.elements._self.classList.remove(this.classes.content.active)
    this.elements._self.setAttribute('aria-hidden', true)
    this.elements.content.setAttribute('aria-hidden', false)
    this.trap.deactivate()
    bodyScrollLock.enableBodyScroll(this.elements._self)
    this.states.closed = true

    Kernel.emitEvent('object.modal.hide', {name: this.name, target: this.elements._self, origin: this.elements.toggle})
  }

  keydown (e) {
    if (e.key === 'Escape') {
      this.hide()
      e.stopPropagation()
    }
  }

  toggle () {
    this.states.closed ? this.show() : this.hide()
  }

  isOpen () {
    return !this.states.closed
  }

  destroy () {
    if (!this.states.closed) {
      this.hide()
    }
  }
}
