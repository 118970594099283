import Scrollbar from 'smooth-scrollbar'

const Kernel = window.Kernel

export class ViewportScroll {
  constructor () {
    this.selectors = {
      items: '[data-in-viewport]',
      scroller: '[data-scrollbar="true"]'
    }

    this.tolerance = 72
    this.subscribed = false
    this.scroller = null
    this.listeners = {
      scroller: status => this.onScroller(status),
      scroll: e => this.onScroll(e),
      resize: e => this.onResize(e)
    }

    Kernel.subscribeTo('viewport.updateItems', () => this.run())
  }

  run () {
    this.elements = {
      items: document.querySelectorAll(this.selectors.items),
      scroller: document.querySelector(this.selectors.scroller)
    }

    if (this.elements.items.length > 0) {
      this.registerItems()

      if (this.scroller === null && this.elements.scroller !== null) {
        this.scroller = Scrollbar.get(this.elements.scroller)
      }

      if (this.subscribed === false) {
        this.subscribe()
      }

      this.viewportItems()
    }
  }

  getOffsetTop () {
    return this.scroller ? this.scroller.scrollTop : (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop)
  }

  registerItems () {
    this.items = []
    this.elements.items.forEach(item => {
      let itemSpeed = parseFloat(item.dataset.parallaxSpeed)

      this.items.push({
        element: item,
        box: item.getBoundingClientRect(),
        speed: itemSpeed,
        direction: item.dataset.parallaxDirection === 'X' ? 'X' : 'Y'
      })
    })

    this.viewportItems()
  }

  subscribe () {
    if (this.scroller !== null) {
      this.scroller.addListener(this.listeners.scroller)
    } else {
      Kernel.subscribeTo('window.scroll', this.listeners.scroll)
    }

    Kernel.subscribeTo('window.resize', this.listeners.resize)

    this.subscribed = true
  }

  onResize (e) {
    this.registerItems()
  }

  onScroller (status) {
    this.viewportItems(status.offset.y)
  }

  onScroll (e) {
    this.viewportItems(this.getOffsetTop())
  }

  viewportItems (offsetTop) {
    this.items.forEach(item => {
      item.box = item.element.getBoundingClientRect()

      if (this.itemInViewport(item, offsetTop)) {
        item.element.dataset.inViewport = true
      }
    })
  }

  itemInViewport (item, offsetTop) {
    const windowHeight = (window.innerHeight || document.documentElement.clientHeight)

    // http://stackoverflow.com/questions/325933/determine-whether-two-date-ranges-overlap
    return (item.box.top - this.tolerance <= windowHeight) && ((item.box.top + item.box.height + this.tolerance) >= 0)
  }
}
