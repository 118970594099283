export class MP4VideoComponent {
  constructor () {
    this.selectors = {
      _self: '[data-video-mp4]'
    }
  }

  run () {
    this.elements = {_self: document.querySelectorAll(this.selectors._self)}

    this.elements._self.forEach(element => element.addEventListener('click', e => this.onClick(element)))
  }
  onClick (element) {
    element.dataset.videoMp4 = true
    const video = element.querySelector('video')

    if (video) {
      video.setAttribute('controls', true)
      video.play()
    }
  }
}
