import Scrollbar from 'smooth-scrollbar'

const Kernel = window.Kernel

export class LocationScroll {
  constructor () {
    this.selectors = {
      items: '[data-scroll-to]',
      scroller: '[data-scrollbar="true"]'
    }

    this.scroller = null
  }

  run () {
    this.elements = {
      items: document.querySelectorAll(this.selectors.items),
      scroller: document.querySelector(this.selectors.scroller)
    }

    if (this.elements.items.length > 0) {
      this.elements.items.forEach(el => el.addEventListener('click', e => this.scrollTo(e)))

      if (this.scroller === null && this.elements.scroller !== null) {
        this.scroller = Scrollbar.get(this.elements.scroller)
      }
    }
  }

  scrollTo (e) {
    const element = e.target
    const target = document.querySelector(element.dataset.scrollTo || element.getAttribute('href'))

    if (target) {
      e.preventDefault()

      if (this.scroller) {
        this.scroller.scrollTo(0, target.offsetTop, 600)
      } else {
        this.scrollToEasing(target.offsetTop, 600)
      }
    }
  }

  scrollToEasing (to, duration) {
    const
    element = document.scrollingElement || document.documentElement,
    start = element.scrollTop,
    change = to - start,
    startDate = +new Date(),
    // t = current time
    // b = start value
    // c = change in value
    // d = duration
    easeInOutQuad = function(t, b, c, d) {
        t /= d/2;
        if (t < 1) return c/2*t*t + b;
        t--;
        return -c/2 * (t*(t-2) - 1) + b;
    },
    animateScroll = function() {
        const currentDate = +new Date();
        const currentTime = currentDate - startDate;
        element.scrollTop = parseInt(easeInOutQuad(currentTime, start, change, duration));
        if(currentTime < duration) {
            requestAnimationFrame(animateScroll);
        }
        else {
            element.scrollTop = to;
        }
    };
    animateScroll();
};
}
