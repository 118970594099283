import Slider from 'objects/slider'

export class SliderProjectComponent {
  constructor () {
    this.selectors = {
      _self: '.presentation__slider',
      text: '.slider--project-description',
      image: '.slider--project-media',
      indicator: '.number__track',
      previous: '.slider__previous',
      next: '.slider__next'
    }

    this.config = {
      text: {
        setGalerySize: false,
        imagesLoaded: false,
        adaptiveHeight: false,
        wrapAround: true,
        pageDots: false,
        prevNextButtons: false,
        percentPosition: false,
        draggable: false,
        fade: true
      },
      image: {
        wrapAround: true,
        draggable: '>1',
        setGallerySize: false,
        adaptiveHeight: false,
        prevNextButtons: false
      }
    }

    this.direction = null

    this.listeners = {
      windowResize: () => this.onWindowResize()
    }
  }

  run () {
    this.sliders = []

    // Find sliders
    const elements = document.querySelectorAll(this.selectors._self)

    elements.forEach(element => {
      // Create Slider set
      const set = {
        text: element.querySelector(this.selectors.text),
        image: element.querySelector(this.selectors.image),
        track: element.querySelector(this.selectors.indicator),
        previous: element.querySelector(this.selectors.previous),
        next: element.querySelector(this.selectors.next)
      }

      // Create text Slider
      const text = new Slider(set.text, this.config.text)

      // Create image Slider
      this.config.image.on = {
        change: index => {
          text.instance().select(index)
          set.track.style.setProperty('--current', index)
        }
      }

      const image = new Slider(set.image, this.config.image)

      // Bind SVG cursors to events
      set.image.addEventListener('mousemove', e => {
        if (e.offsetX <= set.image.offsetWidth / 2 - 64) {
          set.image.classList.remove('cursor--next')
          set.image.classList.add('cursor--prev')
        }  else if (e.offsetX > set.image.offsetWidth / 2 - 64) {
          set.image.classList.remove('cursor--prev')
          set.image.classList.add('cursor--next')
        }
      })

      set.image.addEventListener('click', e => {
        if (e.offsetX <= set.image.offsetWidth / 2 - 64) {
          image.instance().previous()
        } else {
          image.instance().next()
        }
      })

      // Bind navigation to arrows
      set.previous.addEventListener('click', () => image.instance().previous())
      set.next.addEventListener('click', () => image.instance().next())

      // Push Slider set
      this.sliders.push({image: image, text: text})
    })
  }

  onWindowResize () {
    this.sliders.forEach(sliderSet => {
      console.log('Resizing sliders')
      sliderSet.text.recreate()
      sliderSet.image.recreate()
    })
  }

  destroy () {
    this.sliders.forEach(sliderSet => {
      sliderSet.text.destroy()
      sliderSet.image.destroy()
    })

    Kernel.unsubscribeTo('window.resize', this.listeners.windowResize)
  }
}
