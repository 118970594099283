import { ModalObject } from 'objects/modal'

export class MainMenuComponent {
  constructor () {
    this.selectors = {
      open: '.menu__button--open',
      close: '.menu__button--close',
      content: '#main-menu'
    }
  }

  run () {
    this.elements = {
      open: document.querySelector(this.selectors.open),
      close: document.querySelector(this.selectors.close),
      content: document.querySelector(this.selectors.content)
    }

    if (this.elements.content && this.elements.open && this.elements.close) {
      this.modal = new ModalObject(this.elements.content, null, null, 'Main menu')
      this.elements.open.addEventListener('click', () => {
        this.modal.show()
        this.elements.open.setAttribute('aria-expanded', true)
        this.elements.close.setAttribute('aria-expanded', true)
      })
      this.elements.close.addEventListener('click', () => {
        this.modal.hide()
        this.elements.open.setAttribute('aria-expanded', false)
        this.elements.close.setAttribute('aria-expanded', false)
      })
    }
  }
}
