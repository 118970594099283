// Modules (Only run when DOM is ready)
import { SmoothScroll } from 'components/ux/scroll/smooth'
import { PageTransition } from 'components/ux/transitions/page'

// Components (Only run when DOM is ready)
import { MainHeaderComponent } from 'components/header/main'
import { MainMenuComponent } from 'components/menu//main'
import { SliderProjectComponent } from 'components/slider/project'
import { LocationScroll } from 'components/ux/scroll/location'
import { CustomCursor } from 'components/ux/cursor/custom'
import { ViewportScroll } from 'components/ux/scroll/viewport'
import { MP4VideoComponent } from 'components/video/mp4'

const Kernel = window.Kernel
const payload = window.payload

class App {
  constructor (config, deps) {
    this.components = []
    this.modules = []
    this.config = config
    this.deps = deps
  }

  // Module and Component management
  addComponent (Component) {
    Kernel.log(`Add component '${Component.name}'`, 'app')
    this.components.push(new Component(this.config))
  }

  addModule (Module) {
    Kernel.log(`Add module '${Module.name}'`, 'app')
    this.modules.push(new Module(this.config))
  }

  loadComponents () {
    this.components.forEach(component => {
      Kernel.log(`Load component '${component.constructor.name}'`, 'app')
      component.run()
    })
  }

  unloadComponents () {
    this.components.forEach(component => {
      if (typeof component.destroy === 'function') {
        Kernel.log(`Unload component '${component.constructor.name}'`, 'app')
        component.destroy()
      }
    })
  }

  loadModules () {
    this.modules.forEach(module => {
      Kernel.log(`Load module '${module.constructor.name}'`, 'app')
      module.run()
    })
  }

  loadDependencies () {
    if (this.deps && this.deps.length > 0) {
      this.deps.forEach(item => Kernel.loadDependency(item))
    }
  }

  run () {
    if (Kernel.isDomReady()) {
      this.onDomReady()
    } else {
      Kernel.subscribeOnce('dom.ready', this.onDomReady.bind(this))
    }

    Kernel.subscribeTo('pagetransition.end', () => this.onPageTransitionEnd())
    Kernel.subscribeTo('pagetransition.start', () => this.onPageTransitionStart())
  }

  onDomReady () {
    this.loadModules()
    this.loadComponents()
    this.loadDependencies()
  }

  onPageTransitionEnd () {
    this.loadComponents()
  }

  onPageTransitionStart () {
    this.unloadComponents()
  }
}

// App Setup
const config = payload.app && payload.app.config ? payload.app.config : {}
const deps = payload.app && payload.app.deps ? payload.app.deps : {}
const app = new App(config, deps)

app.addModule(SmoothScroll)
app.addModule(PageTransition)

app.addComponent(MainHeaderComponent)
app.addComponent(MainMenuComponent)
app.addComponent(SliderProjectComponent)
app.addComponent(LocationScroll)
app.addComponent(CustomCursor)
app.addComponent(ViewportScroll)
app.addComponent(MP4VideoComponent)

// Run app
app.run()
