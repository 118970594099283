import Scrollbar from 'smooth-scrollbar'

export class MainHeaderComponent {
  constructor () {
    this.selectors = {
      _self: '.main-header',
      navigation: '.main-header .navigation__list',
      scroller: '[data-scrollbar="true"]'
    }

    this.classes = {
      small: 'main-header--small',
      horizontal: 'navigation__list--horizontal'
    }

    this.isSmall = false

    this.subscribed = false
    this.scroller = null
    this.listeners = {
      scroller: status => this.onScroller(status),
      scroll: e => this.onScroll(e)
    }
  }

  run () {
    this.elements = {
      _self: document.querySelector(this.selectors._self),
      navigation: document.querySelector(this.selectors.navigation),
      scroller: document.querySelector(this.selectors.scroller)
    }

    if (this.elements._self) {
      if (this.scroller === null && this.elements.scroller !== null) {
        this.scroller = Scrollbar.get(this.elements.scroller)
      }

      if (this.subscribed === false) {
        this.subscribe()
      }
    }
  }

  subscribe () {
    if (this.scroller !== null) {
      this.scroller.addListener(this.listeners.scroller)
    } else {
      Kernel.subscribeTo('window.scroll', this.listeners.scroll)
    }

    this.subscribed = true
  }

  onScroller (status) {
    this.update(status.offset.y)
  }

  onScroll (e) {
    this.update(window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop)
  }

  update (offsetTop) {
    const addHorizontal = e => {
      this.elements.navigation.removeEventListener(window.transitionEnd, addHorizontal)
      this.elements.navigation.classList.add(this.classes.horizontal)
      this.elements.navigation.removeAttribute('hidden')
    }

    const removeHorizontal = e => {
      this.elements.navigation.removeEventListener(window.transitionEnd, removeHorizontal)
      this.elements.navigation.classList.remove(this.classes.horizontal)
      this.elements.navigation.removeAttribute('hidden')
    }

    if (offsetTop > 4 && !this.isSmall) {
      this.elements.navigation.addEventListener(window.transitionEnd, addHorizontal)
      this.elements._self.classList.add(this.classes.small)
      this.elements.navigation.setAttribute('hidden', true)
      this.isSmall = true
    } else if (offsetTop <= 0 && this.isSmall) {
      this.elements.navigation.addEventListener(window.transitionEnd, removeHorizontal)
      this.elements._self.classList.remove(this.classes.small)
      this.elements.navigation.setAttribute('hidden', true)
      this.isSmall = false
    }
  }
}
